(function ($) {

    $(function () {
        sr = ScrollReveal();
        sr.debug = true;
        //Generals
            sr.reveal('section', {
                duration: 3000,
                opacity: 0.2
                
            });
            sr.reveal('.container', {
                duration: 1500,
                opacity: 0
                
            });
            sr.reveal('h2', {
                duration: 2000,
                origin:'top',
                distance:'50px',
                viewFactor: 0.2
                
            });
            sr.reveal('h1', {
                duration: 2000,
                origin:'top',
                distance:'50px',
                viewFactor: 0.2
                
            });
        
    });
	
}(jQuery));

window.onload = (event) => {
    
    let hero_titles = document.getElementsByClassName("hero-titles");
    let current_title = 0;
    const type_speed = 100;
    const deleteSpeed = 50;
    let animatedTextElement = document.getElementById("animated-text");

    function deleteLetters() {

        var animatedTextElement = document.getElementById("animated-text");

        animatedTextElement.textContent = animatedTextElement.textContent.slice(0, -1);

        if (animatedTextElement.textContent.length > 0) {
            setTimeout(deleteLetters, deleteSpeed);
        } else {
            //move onto the next heading
            current_title++;
            if (hero_titles[current_title]) {
             typeLetter();
            } else {
                current_title = 0;
                typeLetter();
            }
        }

    }

    function typeLetter() {

        //get the current heading from the array and counter
        let txt = hero_titles[current_title].textContent;

        let animatedTextElement = document.getElementById("animated-text");

        let currentAnimatedTextLength = animatedTextElement.textContent.length;

        let nextCharacter = txt.charAt(currentAnimatedTextLength);

        animatedTextElement.textContent += nextCharacter;

        if (animatedTextElement.textContent.length !== txt.length) {
            setTimeout(typeLetter, type_speed);
        } else {
            setTimeout(deleteLetters, 500);
        }
        
    }

    typeLetter();

};

