
(function ($) { 'use strict';
    $(function () {
        
        $( "#addComment" ).on('click', function() {

			$("#respond").removeClass('hide');
            $('html, body').animate({
                scrollTop: $("#respond").offset().top
            }, 500);
        });
    });
}(jQuery));