
document.addEventListener("DOMContentLoaded", function(){

const $dropdown = $(".dropdown");
const $dropdownToggle = $(".dropdown-toggle");
const $dropdownMenu = $(".dropdown-menu");
const showClass = "show";
 
$(window).on("load resize", function() {
  if (this.matchMedia("(min-width: 768px)").matches) {
    $dropdown.hover(
      function() {
        const $this = $(this);
        $this.addClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "true");
        $this.find($dropdownMenu).addClass(showClass);
      },
      function() {
        const $this = $(this);
        $this.removeClass(showClass);
        $this.find($dropdownToggle).attr("aria-expanded", "false");
        $this.find($dropdownMenu).removeClass(showClass);
      }
    );
  } else {
    $dropdown.off("mouseenter mouseleave");
  }
    // suppress page scroll when mobile nav opened
    $(".mobile-toggle-cta").click(function (e) {
      var menuItem = $(this);
      if (menuItem.attr("aria-expanded") === "true") {
        $("body").css("overflow", "hidden");
      } else if (menuItem.attr("aria-expanded") === "false") {
        $("body").css("overflow", "auto");
      }
    });
    $(".nav-link").each( function() {
      $(this).click(function (e) {
        $("body").css("overflow", "auto");
      });
  });
});


(function ($) { 'use strict';

$('.menu-item-type-custom').on('click', function() {
  console.log(this);
  $('#main_nav').removeClass('show');
});

}(jQuery));


}); 


